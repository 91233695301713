import {
  Heading,
  Item,
  Picker,
  MenuTrigger as RS3MenuTrigger,
  ActionButton as RS3ActionButton,
  Menu as RS3Menu,
  Item as RS3Item,
  Flex,
  ComboBox,
  Button,
  ButtonGroup,
  DialogContainer,
  Text,
  useAsyncList,
  TooltipTrigger,
  Tooltip,
  ActionButton,
  ToggleButton,
  Switch,
  View,
} from "@adobe/react-spectrum";
import { React, useEffect, useState } from "react";
import SortableTree, { getNodeAtPath } from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { useOktaAuth } from "@okta/okta-react";
import "./HierarchyManagement.css";
import MoreIcon from "@spectrum-icons/workflow/MoreSmallListVert";
import InfoOutline from "@spectrum-icons/workflow/InfoOutline";
import Pin from "@spectrum-icons/workflow/PinOff";
import Close from "@spectrum-icons/workflow/Close";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
import useUserProfile from "../../context/user-context";
import CloneGTMHierarchyDialog from "../../components/Dialog/HMS/CloneGTMHierarchyDialog";
import GetORGDetailsDialog from "../../components/Dialog/HMS/GetORGDetailsDialog";
import GetSourceSystemDetailsDialog from "../../components/Dialog/HMS/GetSourceSystemDetailsDialog";
import ConfirmRemoveDialog from "../../components/Dialog/HMS/ConfirmRemoveDialog";
import GetNodeHistoryDialog from "../../components/Dialog/HMS/GetNodeHistoryDialog";
import CreateNewGTMHierarchyDialog from "../../components/Dialog/HMS/CreateNewGTMHierarchyDialog";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import MoveNodeDialog from "../../components/Dialog/HMS/MoveNodeDialog";
import PromoteTreeDialog from "../../components/Dialog/HMS/PromoteTreeDialog";
import ClearTreeDialog from "../../components/Dialog/HMS/ClearTreeDialog";
import { hierarchyApi } from "../../api/hierarchyApi";
import { ADMIN, OPERATOR, SUPERUSER, USER } from "../../constants/RoleType";
import useValidUserAccess from "../../hooks/useValidUserAccess";
import { HMS } from "../../constants/CamFunctions";
/* eslint-disable no-underscore-dangle */

export default function HierarchyManagement() {
  const HIERARCHY_FETCH_REQUEST = {};

  // left tree
  const [leftTreeData, setLeftTreeData] = useState([]);
  const [origLeftTreeData, setOrigLeftTreeData] = useState([]);
  const [leftGuName, setLeftGuName] = useState("");
  const [leftTreePinned, setLeftTreePinned] = useState(false);
  const [selectedLeftAccountId, setSelectedLeftAccountId] = useState(null);
  const [leftGuDunsNumber, setLeftGuDunsNumber] = useState(null);
  const [clearLeftTree, setClearLeftTree] = useState(false);
  const [changedLeftData, setChangedLeftData] = useState([]);
  const [leftTreeModified, setLeftTreeModified] = useState(false);
  // right tree
  const [rightTreeData, setRightTreeData] = useState([]);
  const [origRightTreeData, setOrigRightTreeData] = useState([]);
  const [rightGuName, setRightGuName] = useState("");
  const [rightTreePinned, setRightTreePinned] = useState(false);
  const [selectedRightAccountId, setSelectedRightAccountId] = useState(null);
  const [rightGuDunsNumber, setRightGuDunsNumber] = useState(null);
  const [clearRightTree, setClearRightTree] = useState(false);
  const [changedRightData, setChangedRightData] = useState([]);
  const [rightTreeModified, setRightTreeModified] = useState(false);

  const [hierarchyType, setHierarchyType] = useState("ECH Hierarchy");
  const [dunsId, setDunsId] = useState([]);
  const [selectedGuDunsNumber, setSelectedGuDunsNumber] = useState(null); // gu duns for tree when options is selected for node actions
  const [name, setName] = useState();
  const [treeRowInfo, setTreeRowInfo] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [cloneGTMOpen, setCloneGTMOpen] = useState(false);
  const [moveNodeOpen, setMoveNodeOpen] = useState(false);
  const [hierarchyTypeList, setHierarchyTypeList] = useState([]);

  const [selectedIsSourceSystem, setSelectedIsSourceSystem] = useState(null);
  const [camDetailsOpen, setCamDetailsOpen] = useState(false);
  const [nodeHistoryOpen, setNodeHistoryOpen] = useState(false);
  const [sourceSystemDetailsOpen, setSourceSystemDetailsOpen] = useState(false);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [confirmPromoteOpen, setConfirmPromoteOpen] = useState(false);
  const [deleteNode, setDeleteNode] = useState();
  const [callORGDetail, setCallORGDetail] = useState(false);
  const [callSourceSystemDetail, setCallSourceSystemDetail] = useState(false);
  const [createNewGTMHierarchy, setCreateNewGTMHierarchy] = useState(false);
  const [isLoadingDialog, setLoadingDialog] = useState(false);
  const [filterByDuns, setFilterByDuns] = useState(false);

  /* eslint-disable global-require */
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const isHmsActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, OPERATOR, USER],
    HMS
  );
  const isHmsEditActionAllowed = useValidUserAccess([ADMIN], HMS);

  const handleCanDrop = (nodeEvent) => {
    let canDrop = true;
    if (!isHmsEditActionAllowed) {
      canDrop = false;
    }
    if (nodeEvent?.nextPath?.length === 1) {
      canDrop = false;
    }
    if (nodeEvent?.nextParent?.isSourceSystem) {
      canDrop = false;
    }
    return canDrop;
  };

  const traversalTree = (treeNode, nodeList) => {
    if (nodeList.includes(treeNode._id)) {
      // eslint-disable-line no-param-reassign, no-underscore-dangle
      treeNode.modified = true; /* eslint no-param-reassign: "error" */
    }
    if (treeNode.children.length === 0) {
      return;
    }
    treeNode.children.forEach((child) => traversalTree(child, nodeList)); // eslint-disable-line no-param-reassign, no-underscore-dangle
  };

  // traverses tree to check for expanding if searching by non root parent account
  function traverse(element, nextChange) {
    element?.children?.forEach((child) => {
      if (traverse(child, nextChange)) {
        element.isChildModified = true;
        element.expanded = true;
        if (child.modified) {
          nextChange.add(element._id);
        }
        if (child.isSelected) {
          nextChange.add(element._id);
        }
      }
    });

    return (
      element &&
      (element.modified || element.isChildModified || element.isSelected)
    );
  }

  // traverses tree to check for expanding if searching by non root parent account
  const traverseTreeData = (treeNode) => {
    const temp = treeNode;
    const nextChange = new Set();
    temp.forEach((element) => {
      traverse(element, nextChange);
      nextChange.delete(element._id);
    });

    return temp;
  };

  // setOrigTreeData
  const changeSuccessState = (data, setTreeData, setOrigTreeData) => {
    setTreeData(traverseTreeData([...data]));
    setOrigTreeData([...data]);
  };

  const changeErrorState = () => {
    setLeftTreeData([]);
  };

  const targetAccountList = useAsyncList({
    async load({ filterText }) {
      if (!filterText)
        return {
          items: [],
        };
      const json = await hierarchyApi.searchHMS(
        {
          name: filterText,
          hierarchyName: hierarchyType,
          dunsFilter: filterByDuns,
          resultsSize: 50,
        },
        authState.accessToken
      );
      const results = json?.results || [];
      return {
        items: results,
      };
    },
  });

  const getLeftHierarchyDataByAccountId = () => {
    setLoadingDialog(true);
    let cleanedAccountId = selectedLeftAccountId;
    if (cleanedAccountId != null) {
      cleanedAccountId = cleanedAccountId
        .replaceAll("true", "")
        .replaceAll("false", "");
    }
    HIERARCHY_FETCH_REQUEST.duns_number = [cleanedAccountId];
    HIERARCHY_FETCH_REQUEST.hierarchyType = hierarchyType;
    HIERARCHY_FETCH_REQUEST.isSourceSystem = selectedIsSourceSystem;
    hierarchyApi
      .fetchAccountById(HIERARCHY_FETCH_REQUEST, authState.accessToken)
      .then((data) => {
        setLoadingDialog(false);
        setLeftGuDunsNumber(data[0].subtitle);
        setLeftGuName(data[0].title);
        changeSuccessState(data, setLeftTreeData, setOrigLeftTreeData);
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(
          error.response?.data?.message ||
            "Hierarchy not found. Please check the orphan account queue!",
          {
            timeout: 5000,
          }
        );
        changeErrorState();
      });
  };

  useEffect(() => {
    if (selectedLeftAccountId) getLeftHierarchyDataByAccountId();
  }, [selectedLeftAccountId]);

  const getRightHierarchyDataByAccountId = () => {
    setLoadingDialog(true);
    let cleanedAccountId = selectedRightAccountId;
    if (cleanedAccountId != null) {
      cleanedAccountId = cleanedAccountId
        .replaceAll("true", "")
        .replaceAll("false", "");
    }
    HIERARCHY_FETCH_REQUEST.duns_number = [cleanedAccountId];
    HIERARCHY_FETCH_REQUEST.hierarchyType = hierarchyType;
    HIERARCHY_FETCH_REQUEST.isSourceSystem = selectedIsSourceSystem;
    hierarchyApi
      .fetchAccountById(HIERARCHY_FETCH_REQUEST, authState.accessToken)
      .then((data) => {
        setLoadingDialog(false);
        if (data.length === 0) {
          ErrorToast("No hierarchy found", {
            timeout: 5000,
          });
        } else {
          setRightGuDunsNumber(data[0].subtitle);
          setRightGuName(data[0].title);
        }
        changeSuccessState(data, setRightTreeData, setOrigRightTreeData);
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(
          error.response?.data?.message || "Error fetching hierarchy",
          {
            timeout: 5000,
          }
        );
        changeErrorState();
      });
  };

  useEffect(() => {
    if (selectedRightAccountId) getRightHierarchyDataByAccountId();
  }, [selectedRightAccountId]);

  const handleDropDownMenu = (key) => {
    if (key !== "Create New GTM Hierarchy") {
      setHierarchyType(key);
    } else {
      setCreateNewGTMHierarchy(true);
    }
    setLeftTreeData([]);
  };

  const doesNodeExist = (id, nodes) => {
    nodes.forEach((node) => {
      if (node.id === id) return true;
      if (node.children) {
        if (doesNodeExist(id, node.children)) {
          return true;
        }
      }
      return false;
    });
  };

  const handleCanDrag = (nodeEvent) => {
    if (nodeEvent?.node?.isSourceSystem || !isHmsEditActionAllowed) {
      return false;
    }
    return true;
  };

  const onMoveNode = (
    event,
    origTreeData,
    changedData,
    setChangedData,
    setModified
  ) => {
    try {
      if (event.path !== null) {
        // children of new parent in original tree - if the new parent of the moved node was the previous parent there is no modification
        const newParentChildren = getNodeAtPath({
          treeData: origTreeData,
          path: event.path.slice(0, event.path.length - 1),
          getNodeKey: ({ treeIndex }) => treeIndex,
        })?.node?.children.map((nodeInfo) => nodeInfo.subtitle);
        const changedNode = event.node;
        if (
          newParentChildren !== null &&
          !newParentChildren.includes(event.node.subtitle)
        ) {
          setModified(true);
          if (!changedData.includes(changedNode._id))
            setChangedData([...changedData, changedNode._id]); // eslint-disable-line no-param-reassign, no-underscore-dangle
        } else {
          const updatedChangedData = changedData.filter(
            (id) => id !== changedNode._id
          ); // eslint-disable-line no-param-reassign, no-underscore-dangle
          setChangedData([...updatedChangedData]);
          setModified(updatedChangedData.length !== 0);
        }
      } else if (doesNodeExist(event.node?._id, origTreeData))
        setModified(false);
      else {
        const updatedChangedData = changedData.filter(
          (id) => id !== event.node._id
        ); // eslint-disable-line no-param-reassign, no-underscore-dangle
        setChangedData([...updatedChangedData]);
        setModified(updatedChangedData.length !== 0);
      }
    } catch (error) {
      console.log("Error in onMoveNode: ", error);
    }
  };

  const placeholderRenderer = () => <> </>;

  const handleCamDetailsClose = () => {
    setCamDetailsOpen(false);
    setCallORGDetail(false);
  };

  const handleSourceSystemDetailsClose = () => {
    setSourceSystemDetailsOpen(false);
    setCallSourceSystemDetail(false);
  };

  const handleNewGTMHierarchyClose = () => {
    setConfirmRemoveOpen(false);
    hierarchyApi.fetchHierarchyTypes(authState.accessToken).then((data) => {
      data.push("Create New GTM Hierarchy");
      setHierarchyTypeList(data);
    });
  };

  useEffect(() => {
    hierarchyApi.fetchHierarchyTypes(authState.accessToken).then((data) => {
      setHierarchyTypeList(data);
    });
  }, []);

  const modifyTree = (changedData, treeData, setTreeData) => {
    const treeDataTemp = [...treeData];
    treeDataTemp.forEach((root) => traversalTree(root, changedData));
    setTreeData(treeDataTemp);
  };

  // to propose changed to backend:
  useEffect(() => {
    if (changedLeftData.length !== 0 || changedLeftData !== undefined) {
      modifyTree(changedLeftData, leftTreeData, setLeftTreeData);
    }
  }, [changedLeftData]);

  // to propose changed to backend:
  useEffect(() => {
    if (changedRightData.length !== 0 || changedRightData !== undefined) {
      modifyTree(changedRightData, rightTreeData, setRightTreeData);
    }
  }, [changedRightData]);

  const handleContextMenu = (nodeRowInfo, event) => {
    event.preventDefault();
    setTreeRowInfo(nodeRowInfo);
    setDunsId(nodeRowInfo.node.subtitle);
    setName(nodeRowInfo.node.title);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleOnMenuAction = (key, rowInfo, guDunsNumber) => {
    setTreeRowInfo(rowInfo); // eslint-disable-line no-param-reassign, no-underscore-dangle
    setDunsId(
      rowInfo.node.subtitle ? rowInfo.node.subtitle : "System Generated"
    );
    setName(rowInfo?.node?.title);
    setSelectedGuDunsNumber(guDunsNumber);

    if (key === "add") {
      // setAddNodeOpen(true);
    } else if (key === "edit") {
      // setEditNodeOpen(true);
    } else if (key === "remove") {
      setDeleteNode(rowInfo);
      setConfirmRemoveOpen(true);
    } else if (key === "Clone") {
      setCloneGTMOpen(true);
    } else if (key === "ORG Details") {
      setCallORGDetail(true);
      setCamDetailsOpen(true);
    } else if (key === "Source System Details") {
      setCallSourceSystemDetail(true);
      setSourceSystemDetailsOpen(true);
    } else if (key === "Node History") {
      setNodeHistoryOpen(true);
    } else if (key === "move") {
      setDeleteNode(rowInfo);
      setMoveNodeOpen(true);
    } else if (key === "promote") {
      setDeleteNode(rowInfo);
      setConfirmPromoteOpen(true);
    }
  };

  const generateProps = (rowInfo, origTreeData, treeData, guDunsNumber) => {
    const styleClasses = ["accountHierarchy_node"];
    let nodeProps = {};
    nodeProps = {
      buttons: [
        hierarchyType !== "DNB Legal Hierarchy" && (
          <RS3MenuTrigger>
            <RS3ActionButton isQuiet>
              <MoreIcon />
            </RS3ActionButton>
            <RS3Menu
              onAction={(key) => handleOnMenuAction(key, rowInfo, guDunsNumber)}
            >
              {rowInfo?.path?.length === 1 &&
                hierarchyType === "GTM Hierarchy" &&
                isHmsEditActionAllowed && (
                  <RS3Item key="add">Add subsidiary</RS3Item>
                )}
              {rowInfo?.path?.length === 2 &&
                hierarchyType === "GTM Hierarchy" &&
                isHmsEditActionAllowed && (
                  <RS3Item key="edit">Edit subsidiary</RS3Item>
                )}
              {rowInfo?.path?.length === 1 &&
                hierarchyType === "GTM Hierarchy" &&
                isHmsEditActionAllowed && (
                  <RS3Item key="Clone">Clone New GTM Hierarchy</RS3Item>
                )}
              {/* {Number.isNaN(rowInfo?.node?.subtitle) && */}
              {((rowInfo?.path?.length === 3 &&
                hierarchyType === "GTM Hierarchy") ||
                (hierarchyType === "ECH Hierarchy" &&
                  !rowInfo?.node?.isSourceSystem)) && (
                <RS3Item key="ORG Details">View Org Entity Details</RS3Item>
              )}
              {/* {Number.isNaN(rowInfo?.node?.subtitle) && */}
              {(rowInfo?.path?.length === 4 &&
                hierarchyType === "GTM Hierarchy") ||
                (rowInfo?.node?.isSourceSystem && (
                  <RS3Item key="Source System Details">
                    View Source System Details
                  </RS3Item>
                ))}
              {isHmsEditActionAllowed &&
                ((hierarchyType === "GTM Hierarchy" &&
                  rowInfo?.path?.length === 3) ||
                  (hierarchyType === "ECH Hierarchy" &&
                    rowInfo?.path?.length >= 1 &&
                    !rowInfo?.node?.isSourceSystem)) &&
                isHmsEditActionAllowed && (
                  <RS3Item key="Node History">View Node History</RS3Item>
                )}
              {((hierarchyType === "GTM Hierarchy" &&
                rowInfo?.path?.length === 3) ||
                (hierarchyType === "ECH Hierarchy" &&
                  rowInfo?.path?.length >= 1 &&
                  !rowInfo?.node?.isSourceSystem)) &&
                isHmsEditActionAllowed && (
                  <RS3Item key="move">Move Node</RS3Item>
                )}
              {rowInfo?.path?.length !== 1 &&
                hierarchyType === "ECH Hierarchy" &&
                !rowInfo?.node?.isSourceSystem &&
                isHmsEditActionAllowed && (
                  <RS3Item key="promote">Promote Subtree</RS3Item>
                )}
              {rowInfo?.path?.length !== 1 &&
                hierarchyType === "ECH Hierarchy" &&
                !rowInfo?.node?.isSourceSystem &&
                isHmsEditActionAllowed && (
                  <RS3Item key="remove">Remove Node</RS3Item>
                )}
            </RS3Menu>
          </RS3MenuTrigger>
        ),
      ],

      title: (
        <div
          onContextMenu={(e) => {
            handleContextMenu(rowInfo, e);
          }}
          style={{ cursor: "context-menu" }}
        >
          <div>{rowInfo.node.title}</div>
        </div>
      ),
    };

    nodeProps.subtitle = (
      <div className="nodeSubtitle">
        <div>{rowInfo?.node?.subtitle}</div>
        <div>{`${rowInfo?.node?.street} ${rowInfo?.node?.city}, ${rowInfo?.node?.state} ${rowInfo?.node?.country}`}</div>
      </div>
    );

    nodeProps.title = (
      <div className="AHtooltip">
        {rowInfo?.node?.title}
        <div className="right">
          <div className="text-content">
            <Heading
              level={3}
            >{`${rowInfo?.node?.street} ${rowInfo?.node?.city}, ${rowInfo?.node?.state} ${rowInfo?.node?.country}`}</Heading>
          </div>
          <i></i>
        </div>
      </div>
    );

    if (rowInfo?.path?.length === 4) {
      nodeProps.subtitle = (
        <div className="nodeSubtitle">
          <div>{`${rowInfo?.node?.subtitle} | ${rowInfo?.node?.source_system}`}</div>
          <div>{`${rowInfo?.node?.street} ${rowInfo?.node?.city}, ${rowInfo?.node?.state} ${rowInfo?.node?.country}`}</div>
        </div>
      );
    }

    if (
      rowInfo.node.isAdd ||
      (rowInfo.node.isEdit &&
        getNodeAtPath({
          treeData,
          path: rowInfo.path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        })?.node?.title !==
          getNodeAtPath({
            treeData: origTreeData,
            path: rowInfo.path,
            getNodeKey: ({ treeIndex }) => treeIndex,
          })?.node?.title) ||
      (rowInfo.node.modified &&
        !getNodeAtPath({
          treeData: origTreeData,
          path: rowInfo.path.slice(0, rowInfo.path.length - 1),
          getNodeKey: ({ treeIndex }) => treeIndex,
        })
          ?.node?.children.map((nodeInfo) => nodeInfo.subtitle)
          .includes(rowInfo.node.subtitle))
    ) {
      styleClasses.push("cam_tree_node_modified");
    }

    if (rowInfo.node.isSelected) styleClasses.push("cam_tree_node_selected");

    if (rowInfo.node?.isSourceSystem) styleClasses.push("source_system_node");

    nodeProps.className = styleClasses.join(" ");
    return nodeProps;
  };

  const clearTreeData = () => {
    setLeftTreeData([]);
    setLeftTreePinned(false);
    setOrigLeftTreeData([]);
    setSelectedLeftAccountId(null);
    setRightTreeData([]);
    setRightTreePinned(false);
    setOrigRightTreeData([]);
    setSelectedRightAccountId(null);
  };

  const handleMoveNodeClose = (data, newParentId) => {
    setLoadingDialog(true);
    hierarchyApi
      .moveNode(
        {
          hierarchy: "ECH Hierarchy",
          nodeId: data.node.subtitle,
          parentId: newParentId,
          guId: selectedGuDunsNumber,
          userId: user?.userId,
        },
        authState.accessToken
      )
      .then((message) => {
        setLoadingDialog(false);
        SuccessToast(message.message, {
          timeout: 5000,
        });
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(error.response?.data?.message || "Error Moving Node", {
          timeout: 5000,
        });
      });
    clearTreeData();
  };

  const handlePromoteNode = (data, comment) => {
    setLoadingDialog(true);
    hierarchyApi
      .promoteNode(
        {
          hierarchy: "ECH Hierarchy",
          nodeId: data.node.subtitle,
          guId: selectedGuDunsNumber,
          userId: user?.userId,
          comment,
        },
        authState.accessToken
      )
      .then((message) => {
        setLoadingDialog(false);
        SuccessToast(message.message, {
          timeout: 5000,
        });
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(error.response?.data?.message || "Error Promoting Node", {
          timeout: 5000,
        });
      });
    clearTreeData();
  };

  const handleRemoveNode = (data, comment) => {
    setLoadingDialog(true);
    hierarchyApi
      .removeNode(
        {
          hierarchy: "ECH Hierarchy",
          nodeId: data.node.subtitle,
          parentId: data.parentNode.subtitle,
          guId: selectedGuDunsNumber,
          userId: user?.userId,
          comment,
        },
        authState.accessToken
      )
      .then((message) => {
        setLoadingDialog(false);
        SuccessToast(message.message, {
          timeout: 5000,
        });
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(error.response?.data?.message || "Error Promoting Node", {
          timeout: 5000,
        });
      });
    clearTreeData();
  };

  const handleCloneClose = (data) => {
    setCloneGTMOpen(false);
    if (data.action === "clone") {
      hierarchyApi
        .fetchHierarchyTypes(authState.accessToken)
        .then((responseData) => setHierarchyTypeList(responseData));
    }
    clearTreeData();
  };

  const handleRevertNode = (data) => {
    setNodeHistoryOpen(false);
    setLoadingDialog(true);
    hierarchyApi
      .revertNodeHistory(
        {
          gu_duns_number: [selectedGuDunsNumber],
          subtitle: dunsId,
          newParentSubtitle: data.hierarchy[0].subtitle,
          hierarchyType: "ECH Hierarchy",
          userId: user?.userId,
        },
        authState.accessToken
      )
      .then((message) => {
        setLoadingDialog(false);
        if (message.success) {
          SuccessToast(message.message || "Successfully Reverted Node", {
            timeout: 5000,
          });
        } else {
          ErrorToast(message.message || "Error Reverting Node", {
            timeout: 5000,
          });
        }
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(error.response?.data?.message || "Error Reverting Node", {
          timeout: 5000,
        });
      });
    clearTreeData();
  };

  const handlePropose = () => {
    setLoadingDialog(true);
    let treeDataToSend;
    if (origLeftTreeData.length > 0 && origRightTreeData.length > 0) {
      if (
        (leftTreeData.length > 0 && rightTreeData.length === 0) ||
        (rightTreeData.length > 0 && leftTreeData.length === 0)
      ) {
        const mergeGuData = {
          hierarchyType,
          orgId:
            leftTreeData.length > 0
              ? origRightTreeData[0].subtitle
              : origLeftTreeData[0].subtitle,
          orgName:
            leftTreeData.length > 0
              ? origRightTreeData[0].title
              : origLeftTreeData[0].title,
          orgDuns:
            leftTreeData.length > 0
              ? origRightTreeData[0].duns_number
              : origLeftTreeData[0].duns_number,
          guId:
            leftTreeData.length > 0
              ? leftTreeData[0].subtitle
              : rightTreeData[0].subtitle,
          guName:
            leftTreeData.length > 0
              ? leftTreeData[0].title
              : rightTreeData[0].title,
          guDuns:
            leftTreeData.length > 0
              ? origLeftTreeData[0].duns_number
              : origRightTreeData[0].duns_number,
          userId: user?.userId,
        };

        // Calling a different API for merging GU
        hierarchyApi
          .mergeGu(mergeGuData, authState.accessToken)
          .then((message) => {
            setLoadingDialog(false);
            SuccessToast(message.message, {
              timeout: 5000,
            });
          })
          .catch((error) => {
            setLoadingDialog(false);
            ErrorToast(error.response?.data?.message || "Error Merging GU", {
              timeout: 5000,
            });
          });
        clearTreeData();
        setLeftTreeModified(false);
        setRightTreeModified(false);
        return;
      }
    }

    if (leftTreeData.length > 0) {
      treeDataToSend = [...leftTreeData];
    }
    if (rightTreeData.length > 0) {
      if (leftTreeData.length > 0)
        treeDataToSend = [...treeDataToSend, ...rightTreeData];
      else treeDataToSend = [...rightTreeData];
    }
    hierarchyApi
      .proposeHierarchyChange(
        {
          hierarchyType,
          treeData: treeDataToSend,
          userId: user?.userId,
        },
        authState.accessToken
      )
      .then((message) => {
        setLoadingDialog(false);
        SuccessToast(message.message, {
          timeout: 5000,
        });
      })
      .catch((error) => {
        setLoadingDialog(false);
        ErrorToast(error.response?.data?.message || "Error Submitting Change", {
          timeout: 5000,
        });
      });
    clearTreeData();
    setLeftTreeModified(false);
    setRightTreeModified(false);
  };

  return (
    <div className="body">
      {!isHmsActionAllowed && (
        <Flex margin="size-300" gap="size-125" direction="column" width="90%">
          <View marginTop="size-200">
            You are not authorized to access this feature. To gain access please
            subscribe to <b>GRP-HMS</b> under the Groups tab. Select role{" "}
            <b>user</b> for read-only access and <b>admin</b> for edit access.
          </View>
        </Flex>
      )}
      {isHmsActionAllowed && (
        <>
          <LoadingDialog isOpen={isLoadingDialog} />
          <Flex margin="size-300" gap="size-150" wrap>
            <Picker
              label={
                <Flex height="32px" alignItems="center">
                  <Text UNSAFE_style={{ fontSize: "14px" }}>
                    Choose hierarchy type
                  </Text>
                </Flex>
              }
              selectedKey={hierarchyType}
              onSelectionChange={handleDropDownMenu}
            >
              {hierarchyTypeList.map((hierarchy) => (
                <Item key={hierarchy} color="red">
                  {hierarchy}
                </Item>
              ))}
            </Picker>
            <ComboBox
              label={
                <Flex height="32px" alignItems="center">
                  <Flex width="200px">
                    <Text UNSAFE_style={{ fontSize: "14px" }}>
                      Search account
                    </Text>
                    <TooltipTrigger>
                      <ActionButton
                        isQuiet
                        aria-label="Information"
                        height="20px"
                      >
                        <InfoOutline size="S" />
                      </ActionButton>
                      <Tooltip>
                        Blue dropdown items are top parent accounts. Non-blue
                        items belong to accounts deeper in the hierarchy
                      </Tooltip>
                    </TooltipTrigger>
                  </Flex>
                  <Flex direction="column" alignItems="end" width="200px">
                    <Switch
                      isSelected={filterByDuns}
                      onChange={setFilterByDuns}
                      marginEnd="-8px"
                    >
                      <Text>DUNS Only</Text>
                    </Switch>
                  </Flex>
                </Flex>
              }
              placeholder="Search by org account name, orgid, ssid, or duns id"
              menuTrigger="focus"
              onSelectionChange={(val) => {
                if (!leftTreePinned) {
                  if (rightTreePinned && selectedRightAccountId === val) {
                    ErrorToast(
                      "Cannot search for the same hierarchy side by side",
                      {
                        timeout: 5000,
                      }
                    );
                    setSelectedLeftAccountId(null);
                  } else {
                    setSelectedLeftAccountId(val);
                    setSelectedIsSourceSystem(
                      val && val.length > 1 && val.includes("true")
                    );
                  }
                  if (!rightTreePinned) setSelectedRightAccountId(null);
                }
                if (leftTreePinned && !rightTreePinned) {
                  if (selectedLeftAccountId === val) {
                    ErrorToast(
                      "Cannot search for the same hierarchy side by side",
                      {
                        timeout: 5000,
                      }
                    );
                    setSelectedLeftAccountId(null);
                  } else {
                    setSelectedRightAccountId(val);
                    setSelectedIsSourceSystem(
                      val && val.length > 1 && val.includes("true")
                    );
                  }
                }
                if (!leftTreePinned && !rightTreePinned) {
                  setSelectedLeftAccountId(val);
                  setSelectedIsSourceSystem(
                    val && val.length > 1 && val.includes("true")
                  );
                  setRightTreeData([]);
                  setSelectedRightAccountId(null);
                }
              }}
              onInputChange={(val) => {
                targetAccountList.setFilterText(val);
              }}
              items={targetAccountList.items}
              loadingState={targetAccountList.loadingState}
              width="400px"
            >
              {(item) =>
                item !== undefined && (
                  <Item key={item.id + item?.isSourceSystem.toString()}>
                    <Text>
                      {item.isRoot ? (
                        <b style={{ color: "darkblue" }}>{`${
                          item.name
                        } | orgid: ${item.id} | duns: ${
                          item.duns_number === "" ? "null" : item.duns_number
                        }`}</b>
                      ) : (
                        `${item.name} | ${
                          item.isSourceSystem ? "ssid:" : "orgid:"
                        } ${item.id} | duns: ${
                          item.duns_number === "" ? "null" : item.duns_number
                        }`
                      )}
                    </Text>
                  </Item>
                )
              }
            </ComboBox>
            {(leftTreeModified || rightTreeModified) && (
              <ButtonGroup UNSAFE_style={{ alignItems: "flex-end" }}>
                <Button
                  color="primary"
                  variant="accent"
                  onPress={handlePropose}
                >
                  Submit
                </Button>
              </ButtonGroup>
            )}
          </Flex>
          <div style={{ display: "flex", flexDirection: "row", width: "99%" }}>
            {leftTreeData.length >= 1 && (
              <div
                className="AccountHierarchyTree"
                style={{
                  // height: leftTreeHeight * 70 + 25,
                  height: "100vh",
                  width: "50%",
                  marginLeft: "15px",
                }}
                // ref={dropRight}
              >
                <Flex direction="row">
                  <Flex alignItems="center">
                    <Heading level={3} marginStart="10px" marginEnd="10px">
                      {leftGuName}
                    </Heading>
                  </Flex>
                  <TooltipTrigger>
                    <ToggleButton
                      isEmphasized
                      isSelected={leftTreePinned}
                      onChange={setLeftTreePinned}
                      aria-label="Pin"
                      isQuiet
                    >
                      <Pin size="S" />
                    </ToggleButton>
                    <Tooltip>
                      Pin to retain this hierarchy and search for another
                      hierarchy
                    </Tooltip>
                  </TooltipTrigger>
                  <TooltipTrigger>
                    <ActionButton
                      variant="secondary"
                      isQuiet
                      onPress={() => {
                        setClearLeftTree(true);
                        setLeftTreePinned(false);
                      }}
                    >
                      <Close size="S" />
                    </ActionButton>
                    <Tooltip>
                      Remove the hierarchy from the screen as well as clear all
                      changes made to hierarchies on the screen.
                    </Tooltip>
                  </TooltipTrigger>
                </Flex>
                <SortableTree
                  className="tree-sortabletree"
                  treeData={leftTreeData}
                  onChange={setLeftTreeData}
                  onMoveNode={(val) =>
                    onMoveNode(
                      val,
                      origLeftTreeData,
                      changedLeftData,
                      setChangedLeftData,
                      setLeftTreeModified
                    )
                  }
                  canDrag={(object) => handleCanDrag(object)}
                  canDrop={(object) => handleCanDrop(object)}
                  generateNodeProps={(rowInfo) =>
                    generateProps(
                      rowInfo,
                      origLeftTreeData,
                      leftTreeData,
                      leftGuDunsNumber
                    )
                  }
                  expanded
                  placeholderRenderer={placeholderRenderer}
                  rowHeight={70}
                  flex={1}
                  dndType="hierarchy"
                  shouldCopyOnOutsideDrop={false}
                ></SortableTree>
              </div>
            )}
            {rightTreeData.length >= 1 && (
              <div
                className="AccountHierarchyTree"
                style={{
                  height: "100vh",
                  width: "50%",
                  marginLeft: "15px",
                }}
              >
                <Flex direction="row">
                  <Flex alignItems="center">
                    <Heading level={3} marginStart="10px" marginEnd="10px">
                      {rightGuName}
                    </Heading>
                  </Flex>
                  <TooltipTrigger>
                    <ToggleButton
                      isEmphasized
                      isSelected={rightTreePinned}
                      onChange={setRightTreePinned}
                      aria-label="Pin"
                      isQuiet
                    >
                      <Pin size="S" />
                    </ToggleButton>
                    <Tooltip>
                      Pin to retain this hierarchy and search for another
                      hierarchy
                    </Tooltip>
                  </TooltipTrigger>
                  <TooltipTrigger>
                    <ActionButton
                      variant="secondary"
                      isQuiet
                      onPress={() => {
                        setClearRightTree(true);
                        setRightTreePinned(false);
                      }}
                    >
                      <Close size="S" />
                    </ActionButton>
                    <Tooltip>
                      Remove the hierarchy from the screen as well as clear all
                      changes made to hierarchies on the screen.
                    </Tooltip>
                  </TooltipTrigger>
                </Flex>
                <SortableTree
                  className="tree-sortabletree"
                  treeData={rightTreeData}
                  onChange={setRightTreeData}
                  onMoveNode={(val) =>
                    onMoveNode(
                      val,
                      origRightTreeData,
                      changedRightData,
                      setChangedRightData,
                      setRightTreeModified
                    )
                  }
                  canDrag={(object) => handleCanDrag(object)}
                  canDrop={(object) => handleCanDrop(object)}
                  generateNodeProps={(rowInfo) =>
                    generateProps(
                      rowInfo,
                      origRightTreeData,
                      rightTreeData,
                      rightGuDunsNumber
                    )
                  }
                  expanded
                  placeholderRenderer={placeholderRenderer}
                  rowHeight={70}
                  flex={1}
                  dndType="hierarchy"
                  shouldCopyOnOutsideDrop={false}
                ></SortableTree>
              </div>
            )}
          </div>

          <DialogContainer onDismiss={() => setCloneGTMOpen(false)}>
            {cloneGTMOpen && (
              <CloneGTMHierarchyDialog
                onClose={handleCloneClose}
                name={name}
                dunsId={dunsId}
              />
            )}
          </DialogContainer>

          <GetORGDetailsDialog
            open={camDetailsOpen}
            onClose={handleCamDetailsClose}
            orgId={dunsId}
            isCalled={callORGDetail}
          ></GetORGDetailsDialog>

          <GetSourceSystemDetailsDialog
            open={sourceSystemDetailsOpen}
            onClose={handleSourceSystemDetailsClose}
            accountId={dunsId}
            isCalled={callSourceSystemDetail}
          ></GetSourceSystemDetailsDialog>

          <DialogContainer onDismiss={() => setConfirmRemoveOpen(false)}>
            {confirmRemoveOpen && (
              <ConfirmRemoveDialog
                value={deleteNode}
                onClose={handleRemoveNode}
              />
            )}
          </DialogContainer>

          <DialogContainer onDismiss={() => setConfirmPromoteOpen(false)}>
            {confirmPromoteOpen && (
              <PromoteTreeDialog
                onClose={handlePromoteNode}
                value={deleteNode}
                accountId={dunsId}
                accountName={name}
              />
            )}
          </DialogContainer>

          <DialogContainer onDismiss={() => setCreateNewGTMHierarchy(false)}>
            {createNewGTMHierarchy && (
              <CreateNewGTMHierarchyDialog
                onClose={handleNewGTMHierarchyClose}
                value={deleteNode}
              />
            )}
          </DialogContainer>

          <DialogContainer onDismiss={() => setNodeHistoryOpen(false)}>
            {nodeHistoryOpen && (
              <GetNodeHistoryDialog
                onClose={() => setNodeHistoryOpen(false)}
                dunsId={dunsId}
                parentNodeSubtitle={treeRowInfo?.parentNode?.subtitle || ""}
                guDunsNumber={selectedGuDunsNumber}
                onRevert={handleRevertNode}
              />
            )}
          </DialogContainer>

          <DialogContainer onDismiss={() => setMoveNodeOpen(false)}>
            {moveNodeOpen && (
              <MoveNodeDialog
                value={deleteNode}
                onClose={handleMoveNodeClose}
              />
            )}
          </DialogContainer>

          <DialogContainer
            onDismiss={() => {
              setClearLeftTree(false);
              if (rightTreeData.length !== 0)
                setRightTreeData(origRightTreeData);
            }}
          >
            {clearLeftTree && (
              <ClearTreeDialog setTreeSelected={setLeftTreeData} />
            )}
          </DialogContainer>

          <DialogContainer
            onDismiss={() => {
              setClearRightTree(false);
              if (leftTreeData.length !== 0) setLeftTreeData(origLeftTreeData);
            }}
          >
            {clearRightTree && (
              <ClearTreeDialog setTreeSelected={setRightTreeData} />
            )}
          </DialogContainer>
        </>
      )}
    </div>
  );
}
